import * as React from "react"
import { Link } from "gatsby"
import { Grommet } from 'grommet';

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <Grommet plain>
      <div data-is-root-path={isRootPath}>
        {/* <header className="global-header">{header}</header> */}
        <main>{children}</main>
        <footer>
          <div className="global-wrapper">
            © {new Date().getFullYear()}, <strong>Broadband Scanner</strong>
            {` `}
            Powered by <a href="https://www.homebox.co.uk">Homebox</a>
          </div>
        </footer>
      </div>
    </Grommet>
  )
}

export default Layout
